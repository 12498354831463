import React, { useEffect, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { AppContext, App } from '../components/app';
import Layout from '../components/layout';
import Menu from '../components/menu';
import Footer from '../components/footer';
import useAxios from 'axios-hooks';
import Copy2Cliboard from '../util/copy2clipboard';
import { saveAs } from 'file-saver';

const ContactPage = ({ location }) => {
    const context = useContext(AppContext);
    const { state, dispatch } = context;

    useEffect(() => {
        if (!state || !state.affiliateId) {
            navigate('/login');
        }
    }, []);

    const [fhgs] = useAxios('https://www.watch4beauty.com/api/b0zTVZNGjmCuxyR');
    const [rotId] = useAxios(
        'https://s88v5aj97b.execute-api.eu-west-1.amazonaws.com/production/get-rotator-id'
    );

    let loading = fhgs.loading || rotId.loading;
    let error = fhgs.error || rotId.error;

    const copyToClipboard = e => {
        Copy2Cliboard.select_all_and_copy(e.target);
    };

    const save = type => {
        const fileData = fhgs.data.map(item => {
            if (type === 'plain') {
                return `${item.code}?${state.affiliateId}\r\n`;
            } else {
                return `${item.code}?${state.affiliateId};${item.fhg_title};${item.model_nickname};${item.zip}\r\n`;
            }
        });
        var blob = new Blob(fileData, {
            type: `text/${type};charset=utf-8`,
        });
        saveAs(blob, 'fhgs.' + (type === 'plain' ? 'txt' : 'csv'));
    };

    let rotator = false;
    let fhgsList = [];
    if (!loading && !error) {
        const total = fhgs.data.length;
        fhgsList = fhgs.data.map((item, index) => ({
            ...item,
            index: total - index,
        }));
        rotator = fhgsList.find(item => {
            return item.fhg_id === parseInt(rotId.data, 10);
        });
    }

    if (!state || !state.affiliateId) {
        return <div></div>;
    }
    return (
        <Layout>
            <Menu location={location} />

            <h1 className="huge nohyphens">Free hosted galleries</h1>
            <p className="padding-bottom-0">
                Updated every other day. Now in a new version, optimized for
                mobile devices. If for some reason, for example non-working
                scripts, you don't want to redirect free hosted galleries to the
                new version, just add{' '}
                <span className="redtext">&amp;backlink</span> to the end of the
                link.
            </p>
            <div className="spacer line spacer-80">&nbsp;</div>
            {!fhgs.loading && !fhgs.error && (
                <>
                    <p className="link link-button">
                        <a
                            href="#"
                            title="Complete list .CSV"
                            onClick={e => {
                                save('csv');
                                e.preventDefault();
                            }}
                        >
                            Complete list .CSV
                        </a>
                    </p>
                    <p className="link link-button">
                        <a
                            href="#"
                            title="Links only .TXT"
                            onClick={e => {
                                save('plain');
                                e.preventDefault();
                            }}
                        >
                            Links only .TXT
                        </a>
                    </p>
                </>
            )}
            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="nohyphens padding-bottom-0">
                Latest 20&nbsp;photo galleries
            </h1>
            <div className="spacer spacer-80">&nbsp;</div>

            <div className="forms">
                <div className="forms-fix">
                    <form>
                        {!fhgs.loading &&
                            !fhgs.error &&
                            fhgsList
                                .filter(item => item.ft_type === 1)
                                .slice(0, 20)
                                .map(item => (
                                    <React.Fragment key={item.fhg_id}>
                                        <div className="noborder">
                                            <div className="panel">
                                                <img
                                                    src={`https://s5q3w2t8.ssl.hwcdn.net/production/${item.datetime}-${item.update_type}-cover-1280.jpg`}
                                                    alt="W4B"
                                                />
                                            </div>
                                            <div className="spacer spacer-16">
                                                &nbsp;
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    id={`item${item.id}`}
                                                    className="has-value"
                                                    rows="1"
                                                    onFocus={copyToClipboard}
                                                    value={`${item.code}?${state.affiliateId}`}
                                                    readOnly
                                                />
                                                <label
                                                    className="control-label"
                                                    htmlFor={`item${item.id}`}
                                                >
                                                    {item.index} |{' '}
                                                    {item.fhg_title} |{' '}
                                                    {item.model_nickname}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="button-container">
                                            <a
                                                href={`${item.zip}`}
                                                className="button full left"
                                                title=".ZIP"
                                            >
                                                .ZIP
                                            </a>
                                            <a
                                                href={`${item.code}?${state.affiliateId}`}
                                                className="button full right"
                                                target="_blank"
                                                title="View"
                                            >
                                                View
                                            </a>
                                        </div>
                                        <div className="spacer spacer-60">
                                            &nbsp;
                                        </div>
                                    </React.Fragment>
                                ))}
                    </form>
                </div>
            </div>

            <div className="spacer line spacer-24">&nbsp;</div>

            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="nohyphens padding-bottom-0">
                Latest 20&nbsp;video galleries
            </h1>
            <div className="spacer spacer-80">&nbsp;</div>

            <div className="forms">
                <div className="forms-fix">
                    <form>
                        {!fhgs.loading &&
                            !fhgs.error &&
                            fhgsList
                                .filter(item => item.ft_type === 2)
                                .slice(0, 20)
                                .map(item => (
                                    <React.Fragment key={item.fhg_id}>
                                        <div className="noborder">
                                            <div className="panel">
                                                <img
                                                    src={`https://s5q3w2t8.ssl.hwcdn.net/production/${item.datetime}-${item.update_type}-cover-1280.jpg`}
                                                    alt="W4B"
                                                />
                                            </div>
                                            <div className="spacer spacer-16">
                                                &nbsp;
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    id={`item${item.id}`}
                                                    className="has-value"
                                                    rows="1"
                                                    onFocus={copyToClipboard}
                                                    value={`${item.code}?${state.affiliateId}`}
                                                    readOnly
                                                />
                                                <label
                                                    className="control-label"
                                                    htmlFor={`item${item.id}`}
                                                >
                                                    {item.index} |{' '}
                                                    {item.fhg_title} |{' '}
                                                    {item.model_nickname}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="button-container">
                                            <a
                                                href={`${item.zip}`}
                                                className="button full left"
                                                title=".ZIP"
                                            >
                                                .ZIP
                                            </a>
                                            <a
                                                href={`${item.code}?${state.affiliateId}`}
                                                className="button full right"
                                                target="_blank"
                                                title="View"
                                            >
                                                View
                                            </a>
                                        </div>
                                        <div className="spacer spacer-60">
                                            &nbsp;
                                        </div>
                                    </React.Fragment>
                                ))}
                    </form>
                </div>
            </div>

            <div className="spacer line spacer-24">&nbsp;</div>

            <div className="spacer spacer-80">&nbsp;</div>
            <h2 className="nohyphens padding-bottom-0">Gallery rotator</h2>
            <p className="hero padding-bottom-0">Updated every day</p>
            <div className="spacer spacer-80">&nbsp;</div>

            <div className="forms">
                <div className="forms-fix">
                    <form>
                        {rotator && (
                            <>
                                <div className="noborder">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="auto"
                                            className="has-value"
                                            rows="1"
                                            onFocus={copyToClipboard}
                                            value={`http://fhg.bcash4you.com/w4b/fhg-rotator/?${state.affiliateId}`}
                                            readOnly
                                        />
                                        <label
                                            className="control-label"
                                            htmlFor="auto"
                                        >
                                            Gallery rotator
                                        </label>
                                    </div>
                                </div>
                                <div className="button-container">
                                    <a
                                        href={`http://fhg.bcash4you.com/w4b/fhg-rotator/?${state.affiliateId}`}
                                        className="button full"
                                        target="_blank"
                                        title="View"
                                    >
                                        View
                                    </a>
                                </div>
                                <div className="spacer spacer-32">&nbsp;</div>

                                <div className="noborder">
                                    <div className="panel">
                                        <img
                                            src={`https://s5q3w2t8.ssl.hwcdn.net/production/${rotator.datetime}-${rotator.update_type}-cover-1280.jpg`}
                                            alt="W4B"
                                        />
                                    </div>
                                    <div className="spacer spacer-16">
                                        &nbsp;
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="image"
                                            className="has-value"
                                            rows="1"
                                            onFocus={copyToClipboard}
                                            value="http://fhg.bcash4you.com/w4b/fhg-rotator/middle001.jpg"
                                            readOnly
                                        />
                                        <label
                                            className="control-label"
                                            htmlFor="image"
                                        >
                                            Gallery rotator image for hotlinking
                                        </label>
                                    </div>
                                </div>

                                <div className="noborder">
                                    <div className="form-group margin-bottom-0">
                                        <input
                                            type="text"
                                            id="code"
                                            className="has-value"
                                            rows="5"
                                            onFocus={copyToClipboard}
                                            value={`<a href="http://fhg.bcash4you.com/w4b/fhg-rotator/?${state.affiliateId}" title="Watch4beauty"><img src="http://fhg.bcash4you.com/w4b/fhg-rotator/middle001.jpg" border="0" alt="Watch4beauty" /></a>`}
                                            readOnly
                                        />
                                        <label
                                            className="control-label"
                                            htmlFor="code"
                                        >
                                            Gallery rotator HTML code
                                        </label>
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>

            <div className="spacer spacer-80">&nbsp;</div>
            <Footer />
        </Layout>
    );
};

export default ContactPage;
